@import '../../../AndrewsVariable.scss';
.andrew-button {
  padding: $medium-space;
  border: 4px solid black;
  background: none;
  font-size: 1em;
  font-weight: 900;

  &.marginBottom {
    margin-bottom: $large-space;
  }

  &.rocky {
    &:hover {
      animation: rotate 0.7s ease-in-out both;

      span {
        animation: storm 0.7s ease-in-out both;
        animation-delay: 0.06s;
      }
    }
  }

  &.flame {
    &:hover {
      animation: burn 1000ms ease-out forwards;

      &::before {
        animation: flare 1000ms ease-out forwards;
      }

      &::after {
        animation: flare 1000ms ease-out forwards;
      }
    }
  }
}

@keyframes flare {
  100% {
    transform: translateY(-20px) scale(1.5);
    filter: blur(10px);
    opacity: 0;
  }
}

@keyframes burn {
  0% {
    color: rgba(255, 130, 110, 1);
    background: rgba(255, 230, 110, 1);
    box-shadow: 0 0 5px 0 rgba(200, 0, 10, 1), 0 0 5px 0 rgba(230, 30, 10, 0.8),
      0 0 5px 0 rgba(230, 230, 10, 0.6);
  }

  100% {
    color: rgba(0, 0, 0, 1);
    background: rgba(255, 255, 255, 1);
    box-shadow: 0 -35px 40px 30px rgba(255, 130, 10, 0),
      0 -30px 30px 10px rgba(230, 30, 10, 0),
      0 -20px 10px 0 rgba(255, 255, 10, 0);
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg) translate3d(0, 0, 0);
  }

  25% {
    transform: rotate(3deg) translate3d(0, 0, 0);
  }

  50% {
    transform: rotate(-3deg) translate3d(0, 0, 0);
  }

  75% {
    transform: rotate(1deg) translate3d(0, 0, 0);
  }

  100% {
    transform: rotate(0deg) translate3d(0, 0, 0);
  }
}

@keyframes storm {
  0% {
    transform: translate3d(0, 0, 0) translateZ(0);
  }

  25% {
    transform: translate3d(4px, 0, 0) translateZ(0);
  }

  50% {
    transform: translate3d(-3px, 0, 0) translateZ(0);
  }

  75% {
    transform: translate3d(2px, 0, 0) translateZ(0);
  }

  100% {
    transform: translate3d(0, 0, 0) translateZ(0);
  }
}
