@import url('https://fonts.googleapis.com/css?family=Abril+Fatface|Vidaloka&display=swap');

.title-container {
  z-index: 1;
  position: relative;
  --maskX: 0;
  --maskY: 50;
  cursor: pointer;

  .title-wrapper {
    overflow-wrap: break-word;
    position: absolute;
    display: flex;
    flex-direction: row;
    .the-N {
      font-weight: bolder;
      display: block;
      font-size: 10vw;
      letter-spacing: 1;
    }
    h1 {
      font-family: 'helvetica';
      overflow-wrap: break-word;
      word-break: break-all;
      margin-top: 0;
      font-weight: bold;
      font-size: 5vw;
      line-height: 0.99;
    }
    &:first-child {
      .the-N {
        color: #8dd4d9;
      }
      h1 {
        color: #8dd4d9;
      }
    }
    &:last-child {
      transition: all 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.275);
      clip-path: polygon(
        0 0,
        calc(var(--maskX) * 1%) 0,
        calc(var(--maskY) * 1%) 100%,
        0 100%
      );
      .the-N {
        color: #d1eef0;
      }
      h1 {
        color: #d1eef0;
      }
    }
  }
}
