@import url(https://fonts.googleapis.com/css?family=Raleway|Vidaloka&display=swap);
@import url(https://fonts.googleapis.com/css?family=Abril+Fatface|Vidaloka&display=swap);
@import url(https://fonts.googleapis.com/css?family=Poppins:900i);
@import url(https://fonts.googleapis.com/css?family=VT323);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  font-family: 'Raleway', sans-serif; }

p,
button,
span,
div,
article,
section,
input,
textarea,
label,
select {
  font-family: 'Raleway', sans-serif; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Vidaloka', serif; }

.page-padding {
  padding: 24px; }

.nav-bar-header {
  z-index: 10;
  background-color: azure; }
  .nav-bar-header .menu-item {
    z-index: 10;
    padding: 16px;
    color: darkgrey;
    font-size: 2rem;
    text-decoration: none; }
    .nav-bar-header .menu-item:hover {
      background: skyblue; }

.home {
  z-index: 1;
  padding: 24px; }

.title-container {
  z-index: 1;
  position: relative;
  --maskX: 0;
  --maskY: 50;
  cursor: pointer; }
  .title-container .title-wrapper {
    overflow-wrap: break-word;
    position: absolute;
    display: flex;
    flex-direction: row; }
    .title-container .title-wrapper .the-N {
      font-weight: bolder;
      display: block;
      font-size: 10vw;
      letter-spacing: 1; }
    .title-container .title-wrapper h1 {
      font-family: 'helvetica';
      overflow-wrap: break-word;
      word-break: break-all;
      margin-top: 0;
      font-weight: bold;
      font-size: 5vw;
      line-height: 0.99; }
    .title-container .title-wrapper:first-child .the-N {
      color: #8dd4d9; }
    .title-container .title-wrapper:first-child h1 {
      color: #8dd4d9; }
    .title-container .title-wrapper:last-child {
      transition: all 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.275);
      -webkit-clip-path: polygon(0 0, calc(var(--maskX) * 1%) 0, calc(var(--maskY) * 1%) 100%, 0 100%);
              clip-path: polygon(0 0, calc(var(--maskX) * 1%) 0, calc(var(--maskY) * 1%) 100%, 0 100%); }
      .title-container .title-wrapper:last-child .the-N {
        color: #d1eef0; }
      .title-container .title-wrapper:last-child h1 {
        color: #d1eef0; }

.colorizer {
  padding: 24px; }

.component-preview {
  padding: 1.5em; }

.andrew-button {
  padding: 1em;
  border: 4px solid black;
  background: none;
  font-size: 1em;
  font-weight: 900; }
  .andrew-button.marginBottom {
    margin-bottom: 1.5em; }
  .andrew-button.rocky:hover {
    -webkit-animation: rotate 0.7s ease-in-out both;
            animation: rotate 0.7s ease-in-out both; }
    .andrew-button.rocky:hover span {
      -webkit-animation: storm 0.7s ease-in-out both;
              animation: storm 0.7s ease-in-out both;
      -webkit-animation-delay: 0.06s;
              animation-delay: 0.06s; }
  .andrew-button.flame:hover {
    -webkit-animation: burn 1000ms ease-out forwards;
            animation: burn 1000ms ease-out forwards; }
    .andrew-button.flame:hover::before {
      -webkit-animation: flare 1000ms ease-out forwards;
              animation: flare 1000ms ease-out forwards; }
    .andrew-button.flame:hover::after {
      -webkit-animation: flare 1000ms ease-out forwards;
              animation: flare 1000ms ease-out forwards; }

@-webkit-keyframes flare {
  100% {
    transform: translateY(-20px) scale(1.5);
    -webkit-filter: blur(10px);
            filter: blur(10px);
    opacity: 0; } }

@keyframes flare {
  100% {
    transform: translateY(-20px) scale(1.5);
    -webkit-filter: blur(10px);
            filter: blur(10px);
    opacity: 0; } }

@-webkit-keyframes burn {
  0% {
    color: #ff826e;
    background: #ffe66e;
    box-shadow: 0 0 5px 0 #c8000a, 0 0 5px 0 rgba(230, 30, 10, 0.8), 0 0 5px 0 rgba(230, 230, 10, 0.6); }
  100% {
    color: black;
    background: white;
    box-shadow: 0 -35px 40px 30px rgba(255, 130, 10, 0), 0 -30px 30px 10px rgba(230, 30, 10, 0), 0 -20px 10px 0 rgba(255, 255, 10, 0); } }

@keyframes burn {
  0% {
    color: #ff826e;
    background: #ffe66e;
    box-shadow: 0 0 5px 0 #c8000a, 0 0 5px 0 rgba(230, 30, 10, 0.8), 0 0 5px 0 rgba(230, 230, 10, 0.6); }
  100% {
    color: black;
    background: white;
    box-shadow: 0 -35px 40px 30px rgba(255, 130, 10, 0), 0 -30px 30px 10px rgba(230, 30, 10, 0), 0 -20px 10px 0 rgba(255, 255, 10, 0); } }

@-webkit-keyframes rotate {
  0% {
    transform: rotate(0deg) translate3d(0, 0, 0); }
  25% {
    transform: rotate(3deg) translate3d(0, 0, 0); }
  50% {
    transform: rotate(-3deg) translate3d(0, 0, 0); }
  75% {
    transform: rotate(1deg) translate3d(0, 0, 0); }
  100% {
    transform: rotate(0deg) translate3d(0, 0, 0); } }

@keyframes rotate {
  0% {
    transform: rotate(0deg) translate3d(0, 0, 0); }
  25% {
    transform: rotate(3deg) translate3d(0, 0, 0); }
  50% {
    transform: rotate(-3deg) translate3d(0, 0, 0); }
  75% {
    transform: rotate(1deg) translate3d(0, 0, 0); }
  100% {
    transform: rotate(0deg) translate3d(0, 0, 0); } }

@-webkit-keyframes storm {
  0% {
    transform: translate3d(0, 0, 0) translateZ(0); }
  25% {
    transform: translate3d(4px, 0, 0) translateZ(0); }
  50% {
    transform: translate3d(-3px, 0, 0) translateZ(0); }
  75% {
    transform: translate3d(2px, 0, 0) translateZ(0); }
  100% {
    transform: translate3d(0, 0, 0) translateZ(0); } }

@keyframes storm {
  0% {
    transform: translate3d(0, 0, 0) translateZ(0); }
  25% {
    transform: translate3d(4px, 0, 0) translateZ(0); }
  50% {
    transform: translate3d(-3px, 0, 0) translateZ(0); }
  75% {
    transform: translate3d(2px, 0, 0) translateZ(0); }
  100% {
    transform: translate3d(0, 0, 0) translateZ(0); } }

.main-hr {
  width: 30%;
  border: none;
  border-top: 1px solid #c3c3c3; }

.icon-btn {
  width: 50px;
  height: 50px;
  border: 1px solid #cdcdcd;
  background: white;
  border-radius: 25px;
  overflow: hidden;
  position: relative;
  transition: width 0.2s ease-in-out; }

.add-btn:hover {
  width: 120px; }

.add-btn::before,
.add-btn::after {
  transition: width 0.2s ease-in-out, border-radius 0.2s ease-in-out;
  content: '';
  position: absolute;
  height: 4px;
  width: 10px;
  top: calc(50% - 2px);
  background: red; }

.add-btn::after {
  right: 14px;
  overflow: hidden;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px; }

.add-btn::before {
  left: 14px;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px; }

.icon-btn:focus {
  outline: none; }

.btn-txt {
  opacity: 0;
  transition: opacity 0.2s; }

.add-btn:hover::before,
.add-btn:hover::after {
  width: 4px;
  border-radius: 2px; }

.add-btn:hover .btn-txt {
  opacity: 1; }

.add-icon::after,
.add-icon::before {
  transition: all 0.2s ease-in-out;
  content: '';
  position: absolute;
  height: 20px;
  width: 2px;
  top: calc(50% - 10px);
  background: red;
  overflow: hidden; }

.add-icon::before {
  left: 22px;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px; }

.add-icon::after {
  right: 22px;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px; }

.add-btn:hover .add-icon::before {
  left: 15px;
  height: 4px;
  top: calc(50% - 2px); }

.add-btn:hover .add-icon::after {
  right: 15px;
  height: 4px;
  top: calc(50% - 2px); }

#btn-download {
  cursor: pointer;
  display: block;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  -webkit-tap-highlight-color: transparent;
  position: absolute;
  top: calc(50% - 24px);
  left: calc(50% - 24px); }

#btn-download:hover {
  background: rgba(34, 50, 84, 0.03); }

#btn-download svg {
  margin: 16px 0 0 16px;
  fill: none;
  transform: translate3d(0, 0, 0); }

#btn-download svg polyline,
#btn-download svg path {
  stroke: #0077ff;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  transition: all 0.3s ease;
  transition-delay: 0.3s; }

#btn-download svg path#check {
  stroke-dasharray: 38;
  stroke-dashoffset: 114;
  transition: all 0.4s ease; }

#btn-download.downloaded svg .svg-out {
  opacity: 0;
  -webkit-animation: drop 0.3s linear;
          animation: drop 0.3s linear;
  transition-delay: 0.4s; }

#btn-download.downloaded svg path#check {
  stroke: #20cca5;
  stroke-dashoffset: 174;
  transition-delay: 0.4s; }

@-webkit-keyframes drop {
  20% {
    transform: translate(0, -3px); }
  80% {
    transform: translate(0, 2px); }
  95% {
    transform: translate(0, 0); } }

@keyframes drop {
  20% {
    transform: translate(0, -3px); }
  80% {
    transform: translate(0, 2px); }
  95% {
    transform: translate(0, 0); } }

.credit {
  position: fixed;
  right: 20px;
  bottom: 20px;
  transition: all 0.2s ease;
  -webkit-user-select: none;
  -moz-user-select: none;
   -ms-user-select: none;
       user-select: none;
  opacity: 0.6; }

.credit img {
  width: 72px; }

.credit:hover {
  transform: scale(0.95); }

.wrapper {
  display: flex;
  justify-content: center;
  /**************SVG****************/
  /* SVG animations */ }
  .wrapper .cta {
    display: flex;
    padding: 10px 45px;
    text-decoration: none;
    font-family: 'Poppins', sans-serif;
    font-size: 40px;
    color: white;
    background: #6225e6;
    transition: 1s;
    box-shadow: 6px 6px 0 black;
    transform: skewX(-15deg); }
  .wrapper .cta:focus {
    outline: none; }
  .wrapper .cta:hover {
    transition: 0.5s;
    box-shadow: 10px 10px 0 #fbc638; }
  .wrapper .cta span:nth-child(2) {
    transition: 0.5s;
    margin-right: 0px; }
  .wrapper .cta:hover span:nth-child(2) {
    transition: 0.5s;
    margin-right: 45px; }
  .wrapper span {
    transform: skewX(15deg); }
  .wrapper span:nth-child(2) {
    width: 20px;
    margin-left: 30px;
    position: relative;
    top: 12%; }
  .wrapper path.one {
    transition: 0.4s;
    transform: translateX(-60%); }
  .wrapper path.two {
    transition: 0.5s;
    transform: translateX(-30%); }
  .wrapper .cta:hover path.three {
    -webkit-animation: color_anim 1s infinite 0.2s;
            animation: color_anim 1s infinite 0.2s; }
  .wrapper .cta:hover path.one {
    transform: translateX(0%);
    -webkit-animation: color_anim 1s infinite 0.6s;
            animation: color_anim 1s infinite 0.6s; }
  .wrapper .cta:hover path.two {
    transform: translateX(0%);
    -webkit-animation: color_anim 1s infinite 0.4s;
            animation: color_anim 1s infinite 0.4s; }

@-webkit-keyframes color_anim {
  0% {
    fill: white; }
  50% {
    fill: #fbc638; }
  100% {
    fill: white; } }

@keyframes color_anim {
  0% {
    fill: white; }
  50% {
    fill: #fbc638; }
  100% {
    fill: white; } }

.letter-image {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 200px;
  height: 200px;
  transform: translate(-50%, -50%);
  cursor: pointer; }

.animated-mail {
  position: absolute;
  height: 150px;
  width: 200px;
  transition: 0.4s; }
  .animated-mail .body {
    position: absolute;
    bottom: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 0 100px 200px;
    border-color: transparent transparent #e95f55 transparent;
    z-index: 2; }
  .animated-mail .top-fold {
    position: absolute;
    top: 50px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 50px 100px 0 100px;
    -webkit-transform-origin: 50% 0%;
    -webkit-transition: transform 0.4s 0.4s, z-index 0.2s 0.4s;
    -moz-transform-origin: 50% 0%;
    -moz-transition: transform 0.4s 0.4s, z-index 0.2s 0.4s;
    transform-origin: 50% 0%;
    transition: transform 0.4s 0.4s, z-index 0.2s 0.4s;
    border-color: #cf4a43 transparent transparent transparent;
    z-index: 2; }
  .animated-mail .back-fold {
    position: absolute;
    bottom: 0;
    width: 200px;
    height: 100px;
    background: #cf4a43;
    z-index: 0; }
  .animated-mail .left-fold {
    position: absolute;
    bottom: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 50px 0 50px 100px;
    border-color: transparent transparent transparent #e15349;
    z-index: 2; }
  .animated-mail .letter {
    left: 20px;
    bottom: 0px;
    position: absolute;
    width: 160px;
    height: 60px;
    background: white;
    z-index: 1;
    overflow: hidden;
    transition: 0.4s 0.2s; }
    .animated-mail .letter .letter-border {
      height: 10px;
      width: 100%;
      background: repeating-linear-gradient(-45deg, #cb5a5e, #cb5a5e 8px, transparent 8px, transparent 18px); }
    .animated-mail .letter .letter-title {
      margin-top: 10px;
      margin-left: 5px;
      height: 10px;
      width: 40%;
      background: #cb5a5e; }
    .animated-mail .letter .letter-context {
      margin-top: 10px;
      margin-left: 5px;
      height: 10px;
      width: 20%;
      background: #cb5a5e; }
    .animated-mail .letter .letter-stamp {
      margin-top: 30px;
      margin-left: 120px;
      border-radius: 100%;
      height: 30px;
      width: 30px;
      background: #cb5a5e;
      opacity: 0.3; }

.shadow {
  position: absolute;
  top: 200px;
  left: 50%;
  width: 400px;
  height: 30px;
  transition: 0.4s;
  transform: translateX(-50%);
  -webkit-transition: 0.4s;
  -webkit-transform: translateX(-50%);
  -moz-transition: 0.4s;
  -moz-transform: translateX(-50%);
  border-radius: 100%;
  background: radial-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)); }

.letter-image:hover .animated-mail {
  transform: translateY(50px);
  -webkit-transform: translateY(50px);
  -moz-transform: translateY(50px); }

.letter-image:hover .animated-mail .top-fold {
  transition: transform 0.4s, z-index 0.2s;
  transform: rotateX(180deg);
  -webkit-transition: transform 0.4s, z-index 0.2s;
  -webkit-transform: rotateX(180deg);
  -moz-transition: transform 0.4s, z-index 0.2s;
  -moz-transform: rotateX(180deg);
  z-index: 0; }

.letter-image:hover .animated-mail .letter {
  height: 180px; }

.letter-image:hover .shadow {
  width: 250px; }

.wrapper {
  padding: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: wrap;
  height: 100vh; }

button {
  flex: 1 1;
  padding: 25px 50px;
  margin: 25px;
  border-radius: 999px;
  border: 5px solid #284cff;
  font-family: 'VT323', monospace;
  font-size: 25px;
  text-shadow: 0 3px 5px rgba(0, 0, 0, 0.25);
  white-space: nowrap;
  color: #fff;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  background: transparent;
  background-image: url(https://web.archive.org/web/20160312084140im_/http://splatoon.nintendo.com/assets/img/nav-bg-fill-blue.png?1443460871);
  background-repeat: repeat-x;
  background-position: 0 -100%;
  transition: 1.5s ease; }
  button:hover {
    background-position: 500% 100%; }
  button:nth-child(1) {
    -webkit-filter: hue-rotate(20deg);
            filter: hue-rotate(20deg); }
  button:nth-child(2) {
    -webkit-filter: hue-rotate(40deg);
            filter: hue-rotate(40deg); }
  button:nth-child(3) {
    -webkit-filter: hue-rotate(60deg);
            filter: hue-rotate(60deg); }
  button:nth-child(4) {
    -webkit-filter: hue-rotate(80deg);
            filter: hue-rotate(80deg); }
  button:nth-child(5) {
    -webkit-filter: hue-rotate(100deg);
            filter: hue-rotate(100deg); }
  button:nth-child(6) {
    -webkit-filter: hue-rotate(120deg);
            filter: hue-rotate(120deg); }
  button:nth-child(7) {
    -webkit-filter: hue-rotate(140deg);
            filter: hue-rotate(140deg); }
  button:nth-child(8) {
    -webkit-filter: hue-rotate(160deg);
            filter: hue-rotate(160deg); }
  button:nth-child(9) {
    -webkit-filter: hue-rotate(180deg);
            filter: hue-rotate(180deg); }
  button:nth-child(10) {
    -webkit-filter: hue-rotate(200deg);
            filter: hue-rotate(200deg); }
  button:nth-child(11) {
    -webkit-filter: hue-rotate(220deg);
            filter: hue-rotate(220deg); }
  button:nth-child(12) {
    -webkit-filter: hue-rotate(240deg);
            filter: hue-rotate(240deg); }
  button:nth-child(13) {
    -webkit-filter: hue-rotate(260deg);
            filter: hue-rotate(260deg); }
  button:nth-child(14) {
    -webkit-filter: hue-rotate(280deg);
            filter: hue-rotate(280deg); }
  button:nth-child(15) {
    -webkit-filter: hue-rotate(300deg);
            filter: hue-rotate(300deg); }
  button:nth-child(16) {
    -webkit-filter: hue-rotate(320deg);
            filter: hue-rotate(320deg); }
  button:nth-child(17) {
    -webkit-filter: hue-rotate(340deg);
            filter: hue-rotate(340deg); }
  button:nth-child(18) {
    -webkit-filter: hue-rotate(360deg);
            filter: hue-rotate(360deg); }

.add {
  width: 84px;
  height: 84px;
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  transform: scale(0.5);
  transform-origin: 50% 50%;
  transition: transform 0.4s ease; }
  .add a {
    display: block;
    position: relative;
    width: 50%;
    padding-bottom: 50%;
    background: #5628ee;
    -webkit-backface-visibility: hidden;
    transition: border-radius 0.3s ease, transform 0.2s ease; }
    .add a svg {
      display: block;
      width: 14px;
      height: 14px;
      position: absolute;
      left: 50%;
      top: 50%;
      margin: -7px 0 0 -7px;
      opacity: 0;
      fill: #fff;
      transform: scale(0.6) rotate(-45deg);
      transition: all 0.15s ease;
      -webkit-backface-visibility: hidden; }
    .add a:nth-child(1) {
      border-radius: 8px 0 0 0; }
    .add a:nth-child(2) {
      border-radius: 0 0 0 8px; }
    .add a:nth-child(3) {
      border-radius: 0 8px 0 0; }
    .add a:nth-child(4) {
      border-radius: 0 0 8px 0; }
  .add:before, .add:after {
    content: '';
    width: 28px;
    height: 6px;
    border-radius: 3px;
    background: #fff;
    position: absolute;
    left: 50%;
    top: 50%;
    display: block;
    z-index: 1;
    transform-origin: 50% 50%;
    transition: transform 0.25s ease; }
  .add:before {
    transform: translate(-50%, -50%) scaleY(0.76); }
  .add:after {
    transform: translate(-50%, -50%) rotate(90deg) scaleY(0.76); }
  .add:hover {
    transform: scale(1) rotate(45deg);
    transition: transform 0.4s ease 0.1s; }
    .add:hover:before {
      transform: translate(-50%, -50%) scaleY(0.76) scaleX(3); }
    .add:hover:after {
      transform: translate(-50%, -50%) rotate(90deg) scaleY(0.76) scaleX(3); }
    .add:hover a {
      --scale: 1;
      pointer-events: none;
      border-radius: 50%;
      -webkit-animation: pointerEvent 0s linear forwards 0.4s;
              animation: pointerEvent 0s linear forwards 0.4s;
      transition: border-radius 0.15s ease 0.1s, transform 0.25s ease 0.15s; }
      .add:hover a:nth-child(1) {
        transform: translate(-6px, -6px) scale(var(--scale)); }
      .add:hover a:nth-child(2) {
        transform: translate(-6px, 6px) scale(var(--scale)); }
      .add:hover a:nth-child(3) {
        transform: translate(6px, -6px) scale(var(--scale)); }
      .add:hover a:nth-child(4) {
        transform: translate(6px, 6px) scale(var(--scale)); }
      .add:hover a svg {
        opacity: 0.7;
        transition: all 0.3s ease 0.2s;
        transform: scale(1) rotate(-45deg); }
      .add:hover a:hover {
        --scale: 0.92;
        transition: border-radius 0.2s ease 0.1s, transform 0.25s ease 0s; }
        .add:hover a:hover svg {
          opacity: 1;
          transition: all 0.3s ease 0s; }

@-webkit-keyframes pointerEvent {
  100% {
    pointer-events: auto; } }

@keyframes pointerEvent {
  100% {
    pointer-events: auto; } }

.dribbble {
  position: fixed;
  display: block;
  right: 20px;
  bottom: 20px; }
  .dribbble img {
    display: block;
    height: 28px; }


