.nav-bar-header {
  z-index: 10;
  background-color: azure;
  .menu-item {
    z-index: 10;
    padding: 16px;
    color: darkgrey;
    font-size: 2rem;
    text-decoration: none;
    &:hover {
      background: skyblue;
    }
  }
}
