@import url('https://fonts.googleapis.com/css?family=Raleway|Vidaloka&display=swap');

.App {
  font-family: 'Raleway', sans-serif;
}

p,
button,
span,
div,
article,
section,
input,
textarea,
label,
select {
  font-family: 'Raleway', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Vidaloka', serif;
}

.page-padding {
  padding: 24px;
}
