$primary-blue: skyblue;
$primary-red: ruby;
$primary-green: green;
$primay-orange: orange;
$primary-yellow: yellow;

$small-space: 0.5em;
$medium-space: 1em;
$large-space: 1.5em;
$extra-large-space: 2em;
