$button: #5628ee;
$icons: #fff;
$plus: #fff;

.add {
  width: 84px;
  height: 84px;
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  transform: scale(0.5);
  transform-origin: 50% 50%;
  transition: transform 0.4s ease;

  a {
    display: block;
    position: relative;
    width: 50%;
    padding-bottom: 50%;
    background: $button;
    -webkit-backface-visibility: hidden;
    transition: border-radius 0.3s ease, transform 0.2s ease;

    svg {
      display: block;
      width: 14px;
      height: 14px;
      position: absolute;
      left: 50%;
      top: 50%;
      margin: -7px 0 0 -7px;
      opacity: 0;
      fill: $icons;
      transform: scale(0.6) rotate(-45deg);
      transition: all 0.15s ease;
      -webkit-backface-visibility: hidden;
    }

    &:nth-child(1) {
      border-radius: 8px 0 0 0;
    }

    &:nth-child(2) {
      border-radius: 0 0 0 8px;
    }

    &:nth-child(3) {
      border-radius: 0 8px 0 0;
    }

    &:nth-child(4) {
      border-radius: 0 0 8px 0;
    }
  }

  &:before,
  &:after {
    content: '';
    width: 28px;
    height: 6px;
    border-radius: 3px;
    background: $plus;
    position: absolute;
    left: 50%;
    top: 50%;
    display: block;
    z-index: 1;
    transform-origin: 50% 50%;
    transition: transform 0.25s ease;
  }

  &:before {
    transform: translate(-50%, -50%) scaleY(0.76);
  }

  &:after {
    transform: translate(-50%, -50%) rotate(90deg) scaleY(0.76);
  }

  &:hover {
    transform: scale(1) rotate(45deg);
    transition: transform 0.4s ease 0.1s;

    &:before {
      transform: translate(-50%, -50%) scaleY(0.76) scaleX(3);
    }

    &:after {
      transform: translate(-50%, -50%) rotate(90deg) scaleY(0.76) scaleX(3);
    }

    a {
      --scale: 1;
      pointer-events: none;
      border-radius: 50%;
      animation: pointerEvent 0s linear forwards 0.4s;
      transition: border-radius 0.15s ease 0.1s, transform 0.25s ease 0.15s;

      &:nth-child(1) {
        transform: translate(-6px, -6px) scale(var(--scale));
      }

      &:nth-child(2) {
        transform: translate(-6px, 6px) scale(var(--scale));
      }

      &:nth-child(3) {
        transform: translate(6px, -6px) scale(var(--scale));
      }

      &:nth-child(4) {
        transform: translate(6px, 6px) scale(var(--scale));
      }

      svg {
        opacity: 0.7;
        transition: all 0.3s ease 0.2s;
        transform: scale(1) rotate(-45deg);
      }

      &:hover {
        --scale: 0.92;
        transition: border-radius 0.2s ease 0.1s, transform 0.25s ease 0s;

        svg {
          opacity: 1;
          transition: all 0.3s ease 0s;
        }
      }
    }
  }
}

@keyframes pointerEvent {
  100% {
    pointer-events: auto;
  }
}

// Center & dribbble
.dribbble {
  position: fixed;
  display: block;
  right: 20px;
  bottom: 20px;

  img {
    display: block;
    height: 28px;
  }
}
